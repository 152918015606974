import Swiper from "swiper";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from "vh-check";
import Headroom from "headroom.js";

const windowHeight = vhCheck({
    cssVarName: "vh-offset",
});

const header = document.getElementById("header");
const headroom = new Headroom(header, {
    offset: document.getElementById("header").offsetHeight,
});
headroom.init();

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

var aboutCarousel = new Swiper(".swiper-hp", {
    speed: 300,
    loop: false,
    slidesPerView: 1,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
});
var fireProtection = new Swiper(".swiper-protection", {
    speed: 600,
    loop: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
        nextEl: ".next-protection",
        prevEl: ".prev-protection",
    },
});

let protectionNumberActualEl = document.getElementById(
    "protectionNumberActual"
);
let protectionNumberOfSlidesEl = document.getElementById(
    "protectionsNumberOfSlides"
);
let protectionCircleEl = document.getElementById("protectionCircle");
let circlePercentNumber;

//add circle value on slide
function circlePercentNumberOnSlide(
    sliderItem,
    numberOfSlidesEl,
    numberActualEl,
    circleEl
) {
    function addNumberToDivs() {
        if (sliderItem.slides.length < 10) {
            numberOfSlidesEl.innerHTML = "0" + sliderItem.slides.length;
            numberActualEl.innerHTML = "0" + (sliderItem.realIndex + 1);
        } else {
            numberOfSlidesEl.innerHTML = sliderItem.slides.length;
            numberActualEl.innerHTML = sliderItem.realIndex + 1;
        }
    }
    addNumberToDivs();

    sliderItem.on("transitionStart", function() {
        addNumberToDivs();
        addCirclePercentNumber();
    });

    function addCirclePercentNumber() {
        circlePercentNumber =
            (100 * (sliderItem.realIndex + 1)) / sliderItem.slides.length;

        circleEl.setAttribute(
            "class",
            "c100 p" + Math.floor(circlePercentNumber)
        );
    }
    addCirclePercentNumber();
}
if (document.getElementsByClassName("swiper-protection").length) {
    circlePercentNumberOnSlide(
        fireProtection,
        protectionNumberOfSlidesEl,
        protectionNumberActualEl,
        protectionCircleEl
    );
}

var reviewsSwiper = new Swiper(".swiper-reviews", {
    speed: 600,
    loop: false,
    slidesPerView: 1,
    navigation: {
        nextEl: ".next-reviews",
        prevEl: ".prev-reviews",
    },
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
});

let reviewsNumberActualEl = document.getElementById("reviewsNumberActual");
let reviewsNumberOfSlidesEl = document.getElementById("reviewsNumberOfSlides");
let reviewsCircleEl = document.getElementById("reviewsCircle");

if (document.getElementsByClassName("swiper-reviews").length) {
    circlePercentNumberOnSlide(
        reviewsSwiper,
        reviewsNumberOfSlidesEl,
        reviewsNumberActualEl,
        reviewsCircleEl
    );
}

//Scroll up arrow
const scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            document.getElementById("up").classList.add("active");
        } else {
            document.getElementById("up").classList.remove("active");
        }
    } else {
        document.getElementById("up").classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onload = function() {
    scrollUpToggle();
};

window.onscroll = function() {
    scrollUpToggle();
};

//show more
let acc = document.getElementsByClassName("button-content__small");
let i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        let panel = this.previousElementSibling;
        console.log(panel);
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
            this.innerHTML = "show more";
            panel.classList.remove("active");
            this.parentElement.classList.remove("open");
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
            this.innerHTML = "show less";
            panel.classList.add("active");
            this.parentElement.classList.add("open");
        }
    });
}

//Scroll to the contact form
$(".contact-map__button").click(function() {
    event.preventDefault();
    $("html, body").animate(
        {
            scrollTop: $("#contact").offset().top,
        },
        1000
    );
});

document.querySelectorAll(".js-contact-form").forEach((element) => {
    element.addEventListener("submit", function(e) {
        e.preventDefault();
        submitContactForm(e);
    });
});

// Contact form send
function submitContactForm(element) {
    let url = element.target.dataset.url;
    let formButton = document.querySelector(".form__button");
    formButton.disabled = true;

    let formData = {};
    let form = element.target;
    let data = new FormData(form);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }
    fetch(url, {
        method: "POST",
        credentials: "same-origin",
        mode: "same-origin",
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": document
                .getElementsByName("csrf-token")[0]
                .getAttribute("content"),
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.status === 1) {
                form.reset();

                dataLayer.push({ event: "message-sent" });
                notify("success", data.msg ? data.msg : "?");
            } else {
                let obj = data.msg;
                let message = "";
                if (typeof obj === "object") {
                    Object.keys(obj).map(function(key) {
                        message += obj[key] + "\n";
                    });
                } else {
                    message = obj;
                }
                notify("error", message ? message : "?");
            }
        });
    formButton.disabled = false;
}
